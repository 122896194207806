/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const FavouriteOutlineIcon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            d="m7.998 4.847-.04-.04A2.877 2.877 0 0 0 5.96 4C4.373 4 3 5.334 3 7.087c0 .828.31 1.569.808 2.119l.037.041L10 15.558l6.154-6.31.038-.042A3.14 3.14 0 0 0 17 7.087C17 5.334 15.627 4 14.04 4c-.764 0-1.464.3-1.997.808l-.04.038L10 6.899 7.998 4.847Zm2.964-1.75A4.863 4.863 0 0 1 14.04 2C16.78 2 19 4.278 19 7.087a5.14 5.14 0 0 1-1.326 3.462l.003.002-6.979 7.156a.97.97 0 0 1-1.396 0l-6.979-7.156.003-.002A5.14 5.14 0 0 1 1 7.087C1 4.277 3.221 2 5.96 2a4.863 4.863 0 0 1 3.377 1.36l.002-.003.661.678.66-.678.003.002c.096-.091.196-.179.3-.263Z"
        />
    </svg>
);
const ForwardRef = forwardRef(FavouriteOutlineIcon);
export default ForwardRef;
